.auth-selector {
  background-color: #F0F0F2 !important;
  border-radius: 16px !important;
  height: 3.7rem !important;

  button {
    &:hover {
      background-color: inherit !important;
      border-radius: inherit !important;
      box-shadow: none !important;
    }
  }

  > div.absolute {
    border: 5px solid #F0F0F2;
    border-radius: 16px;
    box-shadow: none;
  }
}
