@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
        font-family: Poppins, system-ui, sans-serif;
    }
}

@layer utilities {
    .button-primary {
        @apply inline-flex items-center justify-center enabled:cursor-pointer focus:outline-none transition duration-150 ease-in-out data-[has-left-element=true]:rounded-l-none data-[has-right-element=true]:rounded-r-none data-[has-left-element=true]:h-auto data-[has-right-element=true]:h-auto focus:z-20 border antialiased focus:ring-gray-200 text-sm shadow-none focus:ring focus:ring-offset-1 px-4 h-10 border-transparent text-white focus:bg-primary-darkness disabled:bg-gray-300 disabled:hover:bg-gray-300 bg-primary rounded-full hover:bg-primary-dark hover:text-white active:bg-primary-darkness;
    }
    .button-primary:not([disabled]):hover {
        color: white !important;
    }
    .button-primary.rounded  {
        border-radius: 0.25rem !important;
    }
    .button-danger {
        @apply inline-flex items-center justify-center enabled:cursor-pointer focus:outline-none transition duration-150 ease-in-out data-[has-left-element=true]:rounded-l-none data-[has-right-element=true]:rounded-r-none data-[has-left-element=true]:h-auto data-[has-right-element=true]:h-auto focus:z-20 border antialiased focus:ring-gray-200 text-sm shadow-none focus:ring focus:ring-offset-1 px-4 h-10 border-transparent text-white focus:bg-red-600 disabled:bg-gray-500 disabled:hover:bg-gray-500 bg-red-400 rounded-full hover:bg-red-500 active:bg-red-600;
    }
    .border-shadow-b-gray {
        box-shadow: 0 1px 0 #f3f4f6;
    }

    .border-shadow-b-primary {
        box-shadow: 0 1px 0 #0891B2;
    }

    .border-shadow-b-2-primary {
        box-shadow: 0 2px 0 #0891B2;
    }

    .selector, .selector:hover {
        background-color: #F0F0F2 !important;
        border-radius: 12px !important;
        box-shadow: none !important;
        height: 3.3rem !important;
    }
    .selector > button {
        @apply text-gray-700 text-sm;
    }
    .selector > button:hover {
        background-color: #F0F0F2 !important;
        border-radius: inherit !important;
        box-shadow: none !important;
    }
    .selector > div.absolute {
        @apply bg-white;
        border: 5px solid #F0F0F2;
        border-radius: 12px;
    }
    .drawer-floating {
        @apply rounded-2xl m-4;
        height: calc(100vh - 2.1rem) !important;
    }
    [role=tooltip] {
        z-index: 9999 !important;
    }
    [role=listbox] {
        z-index: 70 !important;
    }
}

@layer components {

}

/* Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: #f5f5f5 #d2d2d2;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 15px;
}

*::-webkit-scrollbar-track {
    background: #f5f5f5;
    border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
    background-color: #d2d2d2;
    border-radius: 14px;
    border: 3px solid #f5f5f5;
}
